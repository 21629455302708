import { TableValuedParameter } from "@itm21st/databroker";
import { User } from "auth0";

export type Application = "fasano" | "";
export type Gender = "M" | "F" | "";
export type loading = "" | "get" | "save";

export interface AAFMAAReport {
    CaseFee: number;
    CaseID: number;
    CaseType: string;
    ControlNumber: number;
    Doctor: string;
    EKG: boolean | string;
    EKGFee: number | null;
    InsuredName: string;
    LargeFileFee: number | null;
    PageCount: number;
    ReportSubmitted: Date;
    Total: number;
    Underwriter: string;
}

export interface AssignmentHistory {
    CaseID: number;
    CaseStatusID: number;
    UserID: string | null;
    CreatedBy: string;
}

export interface AuthResponse {
    token?: {
        access_token: string;
        date_issued: number;
        expires_after: number;
    };
    error?: string;
}

export interface LoginInfo {
    username: string;
    password: string;
}

export enum Role {
    Admin = `FasanoAdmin`,
    CanEditCompletedCase = "FasanoCanEditCompletedCase",
    CompensationAdmin = "FasanoCompensationAdmin",
    EmployeeViewSSN = `EmployeeViewSSN`,
    FasanoUser = "FasanoUser",
    Processor = `FasanoProcessor`,
    Readonly = "FasanoReadonly",
    Reconciler = `FasanoReconciler`,
    ReportWriter = `FasanoReportWriter`,
    TwentyFirstLEUnderwriter = `Underwriting`,
    Underwriter = `FasanoUnderwriter`,
    UserAdministrator = `FasanoUserAdministrator`,
    UWSupport = `UWSupport`,
    ViewCaseSearch = "FasanoViewCaseSearch"
}

export interface CaseCondition {
    CaseConditionID: number;
    CaseID: number;
    ConditionID: number | string;
    Condition: string;
    Debit: number | string;
    CaseStatusID: number;
    CreatedBy: string;
    Status: string;
    SortOrder: number;
    NoQuoteClinicalJudgmentReason: boolean;
    Standard: boolean;
    UserName: string;
    CreatedDate: string;
    Duplicate?: boolean;
}

export enum CaseStatus {
    InHouseLogIn = 1,
    AtMedFileReview = 2,
    Prescreen = 3,
    InHouseAssignment = 4,
    AtDoctor = 5,
    AtUnderwriting = 6,
    PeerReview = 7,
    Reconcilation = 8,
    ReportPreparation = 9,
    ReportQC = 10,
    FinalReview = 11,
    Completed = 12,
    Pending = 13,
    OnHoldForOriginal = 14,
    OnHoldForPayment = 15,
    Voided = 16,
    Cancelled = 17,
    Other = 18,
    FeeReconciliation = 19,
    WaitingOnResponse = 20,
    BackToClient = 21,
    NeedsResearch = 22,
    AtMedicalAbstract = 23
}

export interface Client {
    ClientID: string;
    SentToIntacct?: boolean;
    ClientName: string;
    DisabledDate?: string;
    TotalAmount?: number;
    IncludeInBulkSubmit?: boolean;
}

export interface SubClient {
    SubClientID?: number;
    ClientID?: number;
    Name: string;
    IsEnabled: boolean;
    CreatedBy?: string;
    BillingContactID: number | null;
    IntacctProjectID: string | null;
}

export interface CaseFee {
    CaseFeeID?: number;
    FeeTypeID: number;
    Name: string;
    Amount: number;
    AdHocFeeNotes: string;
}

export interface UwCompensation {
    CaseCompensationID: number;
    CaseID: number;
    UserID: string;
    UnderwriterName: string;
    Notes: string;
    Pages: number;
    TotalCompensation: number;
    CompensationLogic: string;
    BillingNotes: string;
    CreatedDate: Date | string;
    ApprovedDate: Date | string;
    ApprovedBy: string;
    CompensationPublishedDate: Date | string;
    IsDeleted: boolean;
    CompensationTypeID: number;
    Units: number;
    UnitPrice: number;
}

export interface UwCompensationType {
    CompensationTypeID: number;
    Name: string;
    IsHourly: boolean;
    IsDeleted: boolean;
}

export interface Case {
    [key: string]: string | number | Date | boolean | undefined | null;
    AccountingType: string;
    AccountingTypeID: number;
    AdHocCaseNotes: string | null;
    AdHocFee: number | null;
    AdHocFeeNotes: string | null;
    AdjCode: number;
    AssignedBusinessDays?: number | null;
    AssignedTo?: string | null;
    AssignedDate: Date | string | null;
    AssignedToName?: string | null;
    AtPreprocessor: boolean;
    BaseFee: boolean;
    BillForRush: boolean;
    BMI: number;
    BuildDebits: number;
    BusinessDays: number;
    BusinessLine: string;
    BusinessLineID: number;
    CancelledDate: string | null;
    CaseAssignment: string;
    CaseFee: number;
    CaseHasBeenBilled: boolean;
    CaseID: number;
    CasePriority: string;
    CasePriorityID: number;
    CaseStatus: string;
    CaseStatusID: number;
    ClientDisplayName: string | null;
    ClientID?: number;
    ClientName?: string;
    ClientReferenceID: string | null;
    ClientInsuredName: string | null;
    ClinicalJudgment: boolean;
    ClinicalJudgmentMean: number;
    ClinicalJudgmentMedian: number;
    CodingCompleteDate: Date | null;
    CodingPartiallyComplete?: boolean;
    CompensationUnits: "hour" | "minute" | null;
    CompleteReport: boolean;
    ConsultationCaseID: number | null;
    ConsultingPhysician: string;
    ConsultingPhysicianID: string;
    ControlNumber: string;
    CoronaryArteryDiseaseOrStroke: boolean;
    CreatedDate: string;
    CrimeReportDate: string | null;
    CrimeReporter: string;
    DementiaSeverity: string;
    DementiaSeverityID: number;
    DementiaDebits: number;
    DOB: string;
    DateDEATH?: Date;
    EntryNotes: string;
    EntryTypeID: number;
    EntryType: string;
    ExcludeFromDataset: boolean;
    FaceAmount: number | undefined;
    FirstName?: string;
    Flag: string;
    Gender: string;
    GrossPageCount?: number;
    HasRedline: boolean;
    HasUnreadMessages?: boolean;
    HideLE: boolean;
    HideMortalityDistributionGraph: boolean;
    HideMortalityDistributionTable: boolean;
    HideMR: boolean;
    Impairment: string;
    ImpairmentID: number;
    IncludeMedicalRecords: boolean;
    IncludeMortalityDistribution: boolean;
    IncludePhoneInterview: boolean;
    InformationRevokedDate: Date | string;
    InsuredHeight: number;
    InsuredHeightDate: string | null;
    InsuredID?: number;
    InsuredName: string;
    InsuredSmokingStatus: string;
    InsuredSmokingStatusID: number;
    InsuredSmokingStatusName: string;
    InsuredWeight: number;
    InsuredWeightDate: string | null;
    IsCaseLocked: boolean;
    IsInternalOrder: boolean;
    IsDuplicate: boolean;
    IsPrepay: boolean;
    IsSelected?: boolean;
    IsUserCompensatedByTime: boolean;
    LastName?: string;
    Location: string;
    MedicalCategory: string;
    MedicalCategoryID: number;
    MortalityDistributionFee: boolean;
    MortalityRate?: number;
    MortalityTable: string;
    MortalityTableID: number;
    NetPageCount?: number;
    NoQuote: boolean;
    NoQuotePerClient: boolean;
    OpenTwentyFirstLE?: boolean;
    OrderDate?: Date;
    OriginalCaseID: number | null;
    OriginalPublishedDate: string | null;
    PagesToCode?: number;
    PhoneInterviewAttemptDate: string | null;
    PhoneInterviewAttemptFee: boolean;
    PhoneInterviewDate: string | null;
    PhoneInterviewFee: boolean;
    PhysicalCopyFee: boolean;
    PhysicalCopyRequested: boolean;
    PreScreenNotes: string;
    Preliminary: boolean;
    PreviousCaseID?: number;
    PreviousInsuredClientPhoneInterview: string | null;
    PreviousInsuredHeight?: number;
    PreviousInsuredOtherClientPhoneInterview: string | null;
    PreviousInsuredSmokingStatusName?: string;
    PreviousInsuredWeight?: number;
    PrimaryDoctor: string;
    PrimaryDoctorUserID: string;
    PrimaryImpairment: string;
    PrimaryUnderwriter: string;
    PrimaryUnderwriterUserID: string;
    PrimaryReconcilerUserID: string;
    PrimaryReconciler: string;
    PrimaryPeerReviewerUserID: string;
    PrimaryPeerReviewer: string;
    PrimaryPrescreener: string;
    PrimaryPrescreenerUserID: string;
    PriorityFactorDays: number;
    ProcessingFee: boolean;
    ProcessStatus: string;
    Product: string;
    PublishedDate: string | null;
    RecsEnd: string | null;
    RecsStart: string | null;
    ReturnedDate: Date | string | null;
    SSN: string;
    SecondaryDoctor: string;
    SecondaryDoctorUserID: string;
    SecondaryLeDate?: string;
    SecondaryUnderwriter: string;
    SecondaryUnderwriterUserID: number;
    SecondaryReconcilerUserID: string;
    SecondaryReconciler: string;
    SecondaryPeerReviewerUserID: string;
    SecondaryPeerReviewer: string;
    SecondaryPrescreener: string;
    SecondaryPrescreenerUserID: string;
    Servicer: string | null;
    ServicerSentDate: Date | string;
    ServicerProcessedDate: Date | string;
    SpecialProject?: string;
    SpecialProjectID?: number;
    SpecialProjectNotes?: string;
    StatusNotes: string;
    StatusRole: string;
    SubClientID: number | null;
    SubClientName?: string;
    SubmitCreatedDate?: Date;
    SubmitID?: number;
    SubmitNotes: string;
    SuggestedReviewDate: string | null;
    TranslationFee: boolean;
    UpgradedFromCaseID: number | null;
    UpgradedToCaseID: number | null;
}

export interface CaseReportRow {
    AssignedDate: Date;
    BusinessDays: number;
    BusinessLine: string | undefined;
    ClientName: string;
    ClinicalJudgment: boolean;
    CreatedDate: Date;
    IsRevised: boolean;
    PhoneInterviewAttempted: boolean;
    PhoneInterviewCompleted: boolean;
    Priority: string;
    Product: string;
    PublishedDate: Date;
}

export interface CaseSummaryRow {
    BusinessLine: string;
    ClientName: string;
    CaseCount: number;
    NetPageCount: number;
    GrossPageCount: number;
}

export interface CompletedCase {
    CaseID: number;
    ClientName: string;
    LastName: string;
    FirstName: string;
    AssignedToDays: number;
    AssignedDate: Date;
    ReturnedDate: Date;
    HasRedline: boolean;
    CaseStatus: string;
    AssignedStatus: string;
    AssignedStatusID: number;
    AssignedRole: string;
    PublishedDate: Date;
}

export interface IDISearchResult {
    IDISearchResultID: number;
    SubmitID: number;
    InsuredID: number | undefined;
    ResultBody: string;
    UnderwritingApplicationID: number;
}

export interface SSN {
    InsuredID: number;
    SSN: string;
}

export interface Insured {
    [key: string]: string | number | Date | boolean | undefined | null;
    InsuredID?: number;
    FirstName: string;
    MiddleInitial: string | null;
    LastName: string;
    Suffix?: string;
    BirthDate?: Date | string;
    DOB: string;
    DateDEATH?: string | null;
    NOTIFIER?: string;
    DateNOTIFREC?: string | null;
    SSN: string;
    FullSSN?: string;
    Gender: string;
    MostRecentCaseDate?: string;
    IsItmInsured?: boolean;
    ConfidenceRankID?: number | undefined;
    ConfidenceRank?: string;
    ObitScanDate?: string | null;
    OtherMethodDate?: string | null | Date;
    OtherMethod?: string | null;
    SecondaryCauseOfDeath?: string | null;
    InsuredTrackingDate?: string | null;
    InternetSweepDate?: string | null;
    DiscoveryState?: string | null;
    DiscoveryStateDate?: string | null | Date;
    DMFDate?: string | null;
    DeathPlaceState?: string | null;
    DeathDiscoveryDateCalculated?: string | null;
    IsForeignDeath?: boolean;
    InformationRevokedDate?: Date | string;
}

export interface InsuredHistoryDetail {
    InsuredHistoryID: number;
    Field: string;
    OldValue: string | undefined;
    NewValue: string | undefined;
    Notes: string;
    CreatedDate: Date;
    UserID: string;
    User: string;
}

export interface InsuredAliasDetail {
    InsuredAliasID: number;
    FirstName: string;
    MiddleInitial: string;
    LastName: string;
    DOB: Date;
    SSN: string;
    Gender: string;
    Source: string;
    DateCreated: Date;
    CreatedBy: string;
    User: string;
}

export interface CreateCaseInsured {
    InsuredID: number;
    FirstName: string;
    LastName: string;
    DOB: Date | string;
}

export interface CaseFile {
    FileID: number;
    FileTypeID: number;
    FileType: string;
    FileName: string;
    FileKey: string;
    FileSize: number;
    CaseID: number;
    ClientID: number;
    SubmitID: number;
    UsedInUnderwriting: boolean;
    CreatedDate: Date;
    UploadedBy: string;
    SubmitPageCount?: number;
}

export interface ClientFile {
    ClientID: number;
    FileID: number;
    FileTypeID: number;
    FileType: string;
    FileName: string;
    FileKey: string;
    FileSize: number;
    CreatedDate: Date;
    UploadedBy: string;
}

export interface CaseHistoryRow {
    CaseID: number;
    CaseHistoryID: number;
    CaseHistoryUser: string;
    CreatedDate: Date;
    Field: string;
    Event: string;
}

export interface CaseHistoryTable {
    CaseID: number;
    UserID: string;
    CreatedDate: string;
    Field: string;
    Event: string;
}

export interface CaseHistoryInsert {
    CaseID: number;
    UserID?: string;
    Field: string | undefined;
    OldValue?: string;
    NewValue?: string;
    Event?: string;
}

export interface AssignmentHistoryRow {
    CaseID: number;
    CaseStatusID: number;
    Assignee: number;
    AssignmentHistoryUser: string;
    AssignedDate: Date | string;
    ReturnedDate: Date | string;
    UserID: string;
}

export interface AssignmentHistoryReportRow {
    AssignedDate: Date;
    CaseID: number;
    CreatedDate: Date;
    ClinicalJudgment: boolean;
    FirstName: string;
    GrossPageCount: number;
    Informal: string;
    LastName: string;
    Name: string;
    NetPageCount: number;
    Priority: string;
    ReturnedDate: Date;
    Status: string;
    TurnAroundTime: number;
}

export interface Submit {
    ApprovedDate?: Date;
    BillForRush: boolean;
    BirthDate: Date | string;
    BusinessLine: string;
    ClientID: number;
    ClientName: string;
    CreatedDate: Date;
    DateDeleted?: Date | string;
    DeletedBy?: string;
    DeletedReason?: string;
    FaceAmount: number;
    FinalizedDate: Date;
    FirstName: string;
    Gender: "M" | "F" | string;
    HasUnreadMessages?: boolean;
    InsuredID?: number;
    LastName: string;
    Location?: string;
    MatchStatus: string;
    MiddleInitial: string | null;
    NewBirthDate?: string;
    NewFirstName?: string;
    NewGender?: string;
    NewLastName?: string;
    NewSSN?: string;
    Notes: string;
    Product: string;
    SpecialProject?: string;
    SpecialProjectID?: number;
    SSN: string;
    SubClientName: string;
    SubmitChangeID?: number;
    SubmitChangeStatus?: string;
    SubmitChangeStatusID?: number;
    SubmitID: number;
    SubmitStatusID: number | null;
    SubmitStatus?: string;
    Zip: string;
}

export interface NewSubmit {
    ClientID: number | null;
    SubClientID: number | null;
    InsuredID: number | null;
    BillForRush: boolean | null;
    FaceAmount: number | null;
    Notes: string | null;
    ApplicationStateID: number | null;
    ApplicationCountryID: number | null;
    Zip: string | null;
    UserID: string | null;
    SpecialProjectID: number | null;
}

export interface PDFProcessorRequest {
    FileKey: string;
    FileName: string;
    ID: number;
    source: string;
    bucketName: string;
}

export interface SubmitChange {
    AdminUserDate: string;
    AdminUser: string;
    AdminUserID: string;
    Comments: string;
    CreatedDate: string;
    NewBirthDate: string;
    NewFirstName: string;
    NewGender: string;
    NewLastName: string;
    NewSSN: string;
    SelectedInsuredID: number;
    SubmitBirthDate: string;
    SubmitChangeID: number;
    SubmitChangeStatus: string;
    SubmitChangeStatusID: number;
    SubmitFirstName: string;
    SubmitGender: string;
    SubmitID: number;
    SubmitLastName: string;
    SubmitSSN: string;
    UpdatedBy: string;
    UpdatedDate: Date | string;
    User: string;
}

export interface SelectedInsuredSubmitChange {
    SelectedInsuredID: number;
    NewSSN?: string;
    NewFirstName?: string;
    NewLastName?: string;
    NewGender?: string;
    NewBirthDate?: string | Date;
}

export interface SubmitFile {
    CaseID: number;
    ClientID: number;
    SubmitID: number;
    FileID: number;
    FileKey: string;
    FileName: string;
    FileSize: number;
    FileTypeID: number;
    InsuredID: number;
    SubmitPageCount: number;
    FinalPageCount: number;
    UsedInUnderwriting: boolean;
    CreatedDate: Date;
    UploadedBy?: string;
    DeletedDate: Date | null;
    ProcessStartTime: Date | null;
    ProcessEndTime: Date | null;
    ProcessFail: boolean;
    RotationValue: number;
}

export interface MedicalCategoryOption {
    MedicalCategoryID: number;
    Name: string;
}

export interface ImpairmentOption {
    ImpairmentID: number;
    Name: string;
}

export interface CaseStatusOption {
    CaseStatusID: number;
    Name: string;
    SortOrder: number;
    DeletedDate: Date | null;
}

export interface EntryTypeOption {
    EntryTypeID: number;
    Name: string;
    SortOrder: number;
}

export interface SpecialProjectOption {
    SpecialProjectID: number;
    Name: string;
}

export interface UnderwriterOption {
    UserID: number;
    Name: string;
}

export interface StateOption {
    StateID: number;
    Name: string;
    Abbreviation: string;
}

export interface FileTypeOption {
    FileTypeID: number;
    Name: string;
}

export interface ClientMessage {
    MessageID: number;
    SubmitID?: number;
    CaseID?: number;
    Message: string;
    CreatedDate: Date;
    FromUser: string;
    IsToClient: boolean;
    SubmissionEmail: string;
    MarkedReadDate: Date | string | null;
    DeletedDate?: Date | string | null;
    BusinessLine?: string;
    IsInformal: boolean;
}

export interface Email {
    from?: string;
    subject: string;
    to: string;
    body: string;
    isHTML?: boolean;
    cc?: string;
    bcc?: string;
    reply_to?: string;
    SubmitID?: number;
    CaseID?: number;
    notes?: string;
}

export interface EmailQueueEntry {
    DateSent: string;
    Sender: string;
    Recipient: string;
    Subject: string;
    Body: string;
    MessageID: string;
    Notes: string | null;
    ReplyTo: string;
    Bcc: string;
}

export interface PostFileResponse {
    key: string;
}

export interface FasanoEmployee {
    FirstName: string;
    LastName: string;
    UserID: string;
    RoleName: "FasanoDoctor" | "FasanoUnderwriter";
}

export interface FinalMortalityCurve {
    duration: number;
    age: number;
    lx: number;
    px: number;
    qx: number;
    deaths: number;
    cumulativeDeaths: number;
}
export interface MortalityCurve {
    age: number;
    duration: number;
    qxBase?: number;
    //qxRaw?: number;
    qxWeighted?: number;
    qxAdj?: number;
    qx?: number;
    px?: number;
    lx?: number;
    deaths?: number;
    cumulativeDeaths?: number;
}

export interface Underwriter {
    UserID?: string;
    Name?: string;
    IsActive: boolean;
    IsDoctor?: boolean;
    Email: string | null;
    AssignmentNotes: string | null;
    ScheduleNotes: string | null;
    TargetCases: number | string;
    MedicalCategory?: string;
    MedicalCategoryID?: number | string;
    CasesAssigned?: number;
    PayInitial: number | string;
    PayRereview: number | string;
    PayRedos: number | string;
    PayFirstUnassignedRedo: number | string;
    PayUnderwriter: number;
    CreatedDate?: Date | string;
    DeletedDate?: Date | string;
    IsUnderwriter?: boolean;
    CanReceiveCaseAssignmentEmail?: boolean;
    CanViewGuidelines?: boolean;
    CanViewLECalculator?: boolean;
    RecurringCompensationID?: number;
    MonthlyCompensation?: number;
    MonthlyCompensationNotes?: string;
}

export interface RecurringCompensation {
    RecurringCompensationID: number;
    Amount: number;
    Notes: string;
    DeletedDate: string;
    DeletedBy: string;
}

export interface CompareData {
    version: string;
    mean: number;
    median: number;
}

export interface UserInRole {
    UserID: string;
    Username: string;
    FirstName: string;
    LastName: string;
    SysUserID: number;
}

export interface ConfidenceRankOption {
    ConfidenceRankID: number;
    ConfidenceRankDesc: string;
}

export interface ClientUser extends User {
    ClientID?: number;
    UserID?: string | null;
    Admin: boolean;
    Active: boolean;
    AuthID: string;
    SendRegistrationEmail?: boolean;
}

export interface InsuredCase {
    CaseID: number;
    SubmitID: number;
    ClientID: number;
    ClientName: string;
    SubmitCreateDate: string;
    PublishedDate: string;
    EntryType: string;
    SubClientID: number;
    SubClientName: string;
    BusinessLineID: number;
    BusinessLine: string;
    ReportFileKey: string;
    ReportFileName: string;
    Median: number | string;
    Mean: number | string;
    GrossMR: number;
    Version: string;
    InsuredSmokingStatus: string;
    Product: string;
}

export interface PhysicalCopyCase {
    CaseID: number;
    FileKey: string;
    FileName: string;
    InsuredName: string;
    InsuredID: string;
    ClientName: string;
    ClientID: number;
    MailingName: string;
    MailingAddress: string;
    PublishedDate: Date | string | null;
    PhysicalCopySentDate: Date | string | null;
    Sent: "Sent" | "Unsent";
}

export interface IntacctItemID {
    ItemID: string;
    Description: string;
}

export interface CompensationItem {
    AdHocCaseNotes: string;
    BusinessLine?: string;
    CaseAssignedDate: Date | string;
    CaseCompensationID: number;
    CaseHasMultipleAssignments: number;
    CaseID: number | undefined;
    CasePublishedDate: Date | string;
    Client: string;
    ClinicalJudgment: boolean | string;
    CompensationNotes: string;
    CompensationPublishedDate: Date | string;
    EntryType: string;
    Fee: number;
    FeeDescription: string;
    FeeLogic: string;
    GrossPageCount: number;
    Insured: string;
    InsuredLastSeenByDoctor: Date | string | null;
    IsDoctor: boolean;
    MedicalProfessionalName: string;
    MedicalProfessionalNameAndSum: string;
    MedicalProfesionalUserID: number | undefined;
    NetPageCount: number;
    Notes: string | "";
    PhoneInterview: string;
}

export interface UnderwriterAssignmentRow {
    Name: string;
    InHouseLogIn: number;
    InHouseAssignment: number;
    Prescreen: number;
    AtDoctor: number;
    AtUnderwriting: number;
    PeerReview: number;
    Reconciliation: number;
    ReportPreparation: number;
    ReportQC: number;
    FinalReview: number;
    NeedsResearch: number;
    BackToClient: number;
    Other: number;
    AtMedicalAbstract: number;
    GrandTotal: number;
    MedsReview?: number;
}

export interface InformalCase {
    CaseID: number;
    OrderDate: Date;
    PublishedDate: Date;
    PrimaryUnderwriter: string;
    NoQuote: boolean;
    ClinicalJudgmentNoQuote: boolean;
    GrossPageCount: number;
    GrossMR: number;
    NetNetMR: number;
    Median: number;
    Mean: number;
    BusinessDays: number;
    UpgradeCaseID: number;
    UpgradedOrderDate: Date;
    UpgradedPublishedDate: Date;
    UpgradedPrimaryUnderwriter: string;
    UpgradedNoQuote: boolean;
    UpgradedGrossPageCount: number;
    UpgradedGrossMR: number;
    UpgradedNetNetMR: number;
    UpgradedMedian: number;
    UpgradedMean: number;
    UpgradedBusinessDays: number;
}

export interface InformalSummary {
    InformalSubmissions: number;
    InformalIssued: number;
    NoQuotesIssued: number;
    Upgraded: number;
    NoQuotesUpgraded: number;
    AveragePageCount: number;
    TotalPageCount: number;
    AverageBusinessDays: number;
}

export interface BillingReportRow {
    CaseID: number;
    Type: string;
    AccountingType: string;
    Company: string;
    ClientID: number;
    IntacctCustomerID: string;
    Subclient: string;
    LOB: string;
    FirstName: string;
    LastName: string;
    EntryDate: Date;
    BillForRush: boolean;
    PublishedDate: Date;
    CancelledDate: Date;
    EntryType: string;
    FaceAmount: number;
    GrossPageCount: number;
    ClinicalJudgment: boolean;
    IncludePhoneInterview: boolean;
    PhoneInterviewDate: Date;
    PhoneInterviewAttemptDate: Date;
    PreviousPhoneInterviewThisClient: Date;
    PreviousPhoneInterviewOtherClient: Date;
    IncludeMedicalRecords: boolean;
    Amount: number;
    CaseFees: string;
}

export interface CaseCompensationVsCaseFees {
    Expedited: boolean;
    BusinessLine: string;
    CaseID: number;
    CaseCompensation: number;
    CaseFee: number;
    CaseType: string;
    ClientID: number;
    ClientName: string;
    ClinicalJudgment: boolean;
    ENoah: string;
    NeedsReconciliation: boolean;
    NetPageCount: number;
    OrderDate: Date;
    PhoneStatus: string;
    Product: string;
    PublishedDate: Date;
    UnderwritersAndDocs: string;
    SubClientName: string;
}

export interface AdHocCaseDataReportRow {
    CaseID: number;
    PublishedDate: Date;
    ClientID: number;
    ClientName: string;
    ClientReferenceID: string;
    ClientInsuredName: string;
    FeeAmount: number;
    FeeTypeID: number;
    FeeType: string;
    User: string;
    CompensationNotes: string;
    CompensationLogic: string;
    CompensationUnits: number;
    Units: number;
}

export interface FeeType {
    FeeTypeID: number;
    Name: string;
    IsActive: boolean;
}

export declare type SaveInsuredParams = {
    InsuredID: number;
    UserID: string;
    Application: string;
    ChangedFields: TableValuedParameter;
    Notes: string;
};

export type CountryCode =
    | "AD"
    | "AE"
    | "AF"
    | "AG"
    | "AI"
    | "AL"
    | "AM"
    | "AO"
    | "AQ"
    | "AR"
    | "AS"
    | "AT"
    | "AU"
    | "AW"
    | "AX"
    | "AZ"
    | "BA"
    | "BB"
    | "BD"
    | "BE"
    | "BF"
    | "BG"
    | "BH"
    | "BI"
    | "BJ"
    | "BL"
    | "BM"
    | "BN"
    | "BO"
    | "BQ"
    | "BR"
    | "BS"
    | "BT"
    | "BV"
    | "BW"
    | "BY"
    | "BZ"
    | "CA"
    | "CC"
    | "CD"
    | "CF"
    | "CG"
    | "CH"
    | "CI"
    | "CK"
    | "CL"
    | "CM"
    | "CN"
    | "CO"
    | "CR"
    | "CU"
    | "CV"
    | "CW"
    | "CX"
    | "CY"
    | "CZ"
    | "DE"
    | "DJ"
    | "DK"
    | "DM"
    | "DO"
    | "DZ"
    | "EC"
    | "EE"
    | "EG"
    | "EH"
    | "ER"
    | "ES"
    | "ET"
    | "FI"
    | "FJ"
    | "FK"
    | "FM"
    | "FO"
    | "FR"
    | "GA"
    | "GB"
    | "GD"
    | "GE"
    | "GF"
    | "GG"
    | "GH"
    | "GI"
    | "GL"
    | "GM"
    | "GN"
    | "GP"
    | "GQ"
    | "GR"
    | "GS"
    | "GT"
    | "GU"
    | "GW"
    | "GY"
    | "HK"
    | "HM"
    | "HN"
    | "HR"
    | "HT"
    | "HU"
    | "ID"
    | "IE"
    | "IL"
    | "IM"
    | "IN"
    | "IO"
    | "IQ"
    | "IR"
    | "IS"
    | "IT"
    | "JE"
    | "JM"
    | "JO"
    | "JP"
    | "KE"
    | "KG"
    | "KH"
    | "KI"
    | "KM"
    | "KN"
    | "KP"
    | "KR"
    | "KW"
    | "KY"
    | "KZ"
    | "LA"
    | "LB"
    | "LC"
    | "LI"
    | "LK"
    | "LR"
    | "LS"
    | "LT"
    | "LU"
    | "LV"
    | "LY"
    | "MA"
    | "MC"
    | "MD"
    | "ME"
    | "MF"
    | "MG"
    | "MH"
    | "MK"
    | "ML"
    | "MM"
    | "MN"
    | "MO"
    | "MP"
    | "MQ"
    | "MR"
    | "MS"
    | "MT"
    | "MU"
    | "MV"
    | "MW"
    | "MX"
    | "MY"
    | "MZ"
    | "NA"
    | "NC"
    | "NE"
    | "NF"
    | "NG"
    | "NI"
    | "NL"
    | "NO"
    | "NP"
    | "NR"
    | "NU"
    | "NZ"
    | "OM"
    | "PA"
    | "PE"
    | "PF"
    | "PG"
    | "PH"
    | "PK"
    | "PL"
    | "PM"
    | "PN"
    | "PR"
    | "PS"
    | "PT"
    | "PW"
    | "PY"
    | "QA"
    | "RE"
    | "RO"
    | "RS"
    | "RU"
    | "RW"
    | "SA"
    | "SB"
    | "SC"
    | "SD"
    | "SE"
    | "SG"
    | "SH"
    | "SI"
    | "SJ"
    | "SK"
    | "SL"
    | "SM"
    | "SN"
    | "SO"
    | "SR"
    | "SS"
    | "ST"
    | "SV"
    | "SX"
    | "SY"
    | "SZ"
    | "TC"
    | "TD"
    | "TF"
    | "TG"
    | "TH"
    | "TJ"
    | "TK"
    | "TL"
    | "TM"
    | "TN"
    | "TO"
    | "TR"
    | "TT"
    | "TV"
    | "TW"
    | "TZ"
    | "UA"
    | "UG"
    | "UM"
    | "US"
    | "UY"
    | "UZ"
    | "VA"
    | "VC"
    | "VE"
    | "VG"
    | "VI"
    | "VN"
    | "VU"
    | "WF"
    | "WS"
    | "YE"
    | "YT"
    | "ZA"
    | "ZM"
    | "ZW";

export interface MortalityRating {
    CaseNumber: number;
    CaseStatus: string;
    InsuredID: number;
    CreatedDate: Date;
    PublishedDate: Date;
    ClientName: string;
    BusinessLine: string;
    Priority: string;
    Product: string;
    ClinicalJudgement: string;
    Preliminary: string;
    PrimaryImpairment: string;
    Median: number;
    Mean: number;
    GrossMortalityRating: number;
    NetMortalityRating: number;
    NetNetMortalityRating: number;
    SmokingStatus: string;
}
